
.right-chat .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list{
    width: 100% !important;
    display: flex;
    justify-content: space-evenly;
}

.right-chat .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab{
    width: 200px;
    justify-content: center;
    margin: 0;
}
