.icon-only-menu {
  height: 100%;
  background-color: #fff;
  border-right: none;
}

.icon-only-menu .ant-menu-item {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  font-size: 24px;
}

.icon-only-menu .ant-menu-item-selected {
  background-color: #f0f0f0 !important;
}

.user-list-header {
  display: flex;
  align-items: center;
  padding: 10px 5px 5px 5px;
  background-color: #fff;
  margin: 0px 2px 2px 2px;
}

.user-name {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.user-message {
  font-size: 13px;
  color: #888;
  margin-top: 5px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-actions {
  display: flex;
  align-items: center;
}

.message-time {
  font-size: 12px;
  color: #999;
  margin-right: 10px;
}

.action-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  background-color: transparent;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
}

.ant-avatar {
  width: 40px;
  height: 40px;
}

.ant-list-item-meta-title {
  margin-bottom: 0;
}

.chat-window-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* background-color: #f7f7f7; */
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-right: 2px solid #f0f0f0;
}


.chat-user-info {
  flex: 1;
  margin-left: 10px;
}

.chat-user-info h3 {
  margin: 0;
  font-size: 16px;
}

.chat-user-info p {
  margin: 0;
  font-size: 14px;
  color: #888;
}

.chat-actions button {
  margin-left: 10px;
}

.chat-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.chat-content::-webkit-scrollbar {
  width: 6px;
}

.chat-content::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}

.chat-content::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 10px;
}

.chat-content::-webkit-scrollbar-thumb:hover {
  background: #555555;
}

.message {
  display: flex;
  margin-bottom: 10px;
}

.message.left {
  justify-content: flex-start;
  padding: 0px;
  border-block-end: none;
}

.message.right {
  justify-content: flex-end;
  padding: 0px;
  border-block-end: none;
}

.message-content {
  max-width: 70%;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.message.right .message-content {
  background-color: #40a9ff;
  color: #fff;
}

.contact {
  display: block;
  font-size: 12px;
  color: #40a9ff;
}

.chat-input-area {
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  align-items: center;
  border-right: 2px solid #f0f0f0;
}

.chat-input-area .input-icons button {
  margin-left: 10px;
}

.avatar-section {
  flex: 0 0 50px;
  margin-left: 5px;
}

.content-section {
  flex: 1;
  padding-left: 10px;
}

.action-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.action-buttons {
  display: flex;
}

.action-icon:hover {
  background-color: #f0f0f0;
  color: #40a9ff;
}

.user-list-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  max-width: 350px;
  min-width: 350px;
  margin: auto;
}

.search-input {
  flex-grow: 1;
  margin-right: 10px;
}

.action-icon-header {
  margin-left: 5px;
}



.custom-list-item {
  background-color: #fff;
  margin-bottom: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 2px 2px 2px 2px;
  padding: 5px !important;
  align-items: self-start !important;
  display: flex;
}


.chat-input-area {
  background-color: white;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: block;
  margin-top: 0;
}

.chat-input-area .ant-input-affix-wrapper {
  flex-grow: 1;
  border: none;
  box-shadow: none;
  padding-left: 10px;
  height: 60px;
}

.chat-input-area .ant-input {
  border: none;
  box-shadow: none;
}

.chat-input-area .ant-btn {
  border-radius: 20px;
}

.input-icons .ant-avatar {
  cursor: pointer;
}

.message-actions {
  margin-top: 5px;
  display: flex;
  gap: 10px;
}

.action-button {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 5px;
}

.action-button:hover {
  text-decoration: underline;
}

.message-content-wrapper {
  position: relative;
  max-width: 80%;
  margin-bottom: 10px;
}

.message.left .message-content-wrapper {
  display: flex;
  justify-content: flex-start;
}

.message.right .message-content-wrapper {
  display: flex;
  justify-content: flex-end;
}

.message-content {
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px;
  max-width: 100%;
  word-wrap: break-word;
}

.message.right .message-content {
  background-color: #28a745;
  color: white;
  text-align: right;
  border-radius: 8px 8px 0px 8px;
  white-space: pre-line;
}

.message.left .message-content {
  background-color: #f1f1f1;
  color: black;
  border-radius: 8px 8px 8px 0px;
}

.hover-buttons {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.message-content-wrapper:hover .hover-buttons {
  display: flex;
}

.message.right .hover-buttons {
  right: 100%;
}

.message.left .hover-buttons {
  left: 100%;
}

.message-content {
  display: block;
  width: 100%;
  word-wrap: break-word;
  word-break: break-word;
}

.message-content p {
  margin: 0;
  white-space: normal;
  word-break: break-word;
}

.message-content a {
  color: #1890ff;
  text-decoration: none;
  word-break: break-all;
}


.custom-tabs {
  display: flex;
  justify-content: center;
  /* border-bottom: 1px solid #e8e8e8; */
  margin-bottom: 16px;
  width: 100%;
}





.topBar {
  background-color: #247ba0;
  padding: 10px;
}

.none-conversation-content {
  position: fixed;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

::scrollbar {
  width: 6px;
  height: 6px;
}

::scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

::scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

::-o-scrollbar {
  width: 6px;
  height: 6px;
}

::-o-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

::-o-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

::-ms-scrollbar {
  width: 6px;
  height: 6px;
}

::-ms-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

::-ms-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.search .ant-input {
  background-color: #ebebeb;
  width: 92%;
  font-size: 16px;
  margin: 0 4%;
  border: 1px solid #ebebeb;
}

.titleSearch {
  width: auto;
  text-align: center;
  font-size: 20px;
  padding: 10px 0 10px 0;
  font-weight: 700;
}


:where(.css-dev-only-do-not-override-ccdg5a).ant-modal .ant-modal-content {
  box-shadow: none !important
}
:where(.css-dev-only-do-not-override-ccdg5a).ant-popover .ant-popover-inner {
  box-shadow: none !important
}

.infinite-scroll-component {
  overflow:unset !important;
}

.custom-list-item.selected-comment {
  background-color: #f0f8ff; 
  border: 1px solid #5099e7; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.blinking-text {
  animation: blink 1s infinite;
}

#root > div > div.user-list-container > div.ant-tabs.ant-tabs-top.ant-tabs-centered.css-dev-only-do-not-override-ccdg5a > div.ant-tabs-nav{
  margin: 0 !important;
}
.user-list-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab{
  width: 173px;
  justify-content: center;
  margin: 0;
}
:where(.css-dev-only-do-not-override-ccdg5a).ant-pagination .ant-pagination-item{
  min-width:40px;
  height: 40px;
  line-height: 37px;
}