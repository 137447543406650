.filterbar .fa {
    font-size: 20px !important;
  }
  .sidebar2 .fa {
    font-size: 28px !important;
  }
  
  .sidebar2 {
    z-index: 0;
    width: 52px;
    height: 100% !important;
    background-color: #fff;
  }
  /* .sidebar2:hover{
      overflow-x: hidden;
      overflow-y: scroll;
  } */
  .sidebar-nav {
    top: 0;
    width: 52px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .sidebar-nav li {
    color: #70c1b3;
    line-height: 50px;
    display: block;
    text-indent: 1px;
    text-align: center;
    cursor: pointer;
    opacity: 0.5;
  }
  
  @media (max-height: 675px) {
    .sidebar-nav li {
      /* line-height:calc((100vh - 50px) / 14);   */
      line-height: 35px;
    }
  }
  @media (max-height: 769px) {
    .sidebar-nav li {
      /* line-height: 40px; */
      line-height:calc((100vh - 50px) / 15);   
    }
  }
  .sidebar-selected,
  .sidebar-nav li:hover {
    text-decoration: none;
    color: rgb(224, 25, 25) !important;
    opacity: 1 !important;
  }
  .sidebar2 .sidebar-nav .filter-tooltip {
    display: none;
    position: absolute;
    font-size: 11px;
    color: #fff;
    padding: 2px 3px;
    border-radius: 3px;
    z-index: 10009999;
    top: 3px;
    left: calc(100% + 5px);
    min-width: 80px;
    line-height: 20px;
  }
  .sidebar2 .sidebar-nav .filter-tooltip:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #111;
    border-width: 5px;
    margin-top: -5px;
  }
  .sidebar2 .sidebar-nav li {
    position: relative;
  }
  .sidebar2 .sidebar-nav li:hover .filter-tooltip {
    display: block;
  }
  
  @media (min-width: 564px) {
    .wrap_content .daterangepicker {
      width: auto;
    }
  }
  .sidebar2 .setting .fa {
    color: #fff;
  }
  .sidebar2 .card .fa {
    color: #fff;
    margin-top: 50%;
  }
  .sidebar-nav li:hover .anticon {
    color: #1890ff !important;
  }
  
  .sidebar-selected .anticon {
    color: #1890ff !important;
  }
  