
body {
  margin: 0;
  height: 100vh;
  background-color: aliceblue;
}

.disable-bg{
  background-color: #ffb4b0;
}

.text-line-3{
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Số dòng tối đa hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
html{
  scroll-behavior: smooth;
}
.ant-table-cell{
  padding: 5px !important;
}
.ant-table-footer{
  padding: 5px 20px !important;
}
.ant-table{
  border-radius:8px !important;
}
.ant-timeline-item-last{
  padding-bottom: 0 !important;
}
.bodyPrint {
  font-family: Arial, sans-serif;
  margin-top: 5px;
  margin: 5px;
  font-size: 12px;
}

.bodyPrintJAT {
  font-family: Arial, sans-serif;
  margin-top: 5px;
  margin: 5px;
  font-size: 14px;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}